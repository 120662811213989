import React from "react";
import Vitrine from "../assets/site_vitrine.png"
import Blog from "../assets/blog.jpeg"
import Ecommerce from "../assets/ecommerce.jpg"
import Personnalise from "../assets/web_dev.png"
import Maintenance from "../assets/Maintenance.jpg"
import {Link} from 'react-scroll';

const Tarifs = () => {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div name='tarifs' className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Mes tarifs</p>
                    <p className='py-6'>// Voici mes tarifs. Passez votre curseur sur chaque case pour les détails.</p>
                </div>
                {/*Container*/}
                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

                    {/*Grid Items*/}
                    <div style={{backgroundImage: `url(${Vitrine})`}}
                         className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        {/*Hover*/}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                Site Vitrine standard
                            </span>
                            <div className='pt-8 text-center'>
                                <p>380€/jour</p>
                                <p>Possibilité avec Wordpress</p>
                                <p>Pack 5 pages</p>
                                <>
                                    <button
                                        className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg"
                                        type="button"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Plus
                                    </button>
                                    {showModal ? (
                                        <>
                                            <div
                                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                            >
                                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                    {/*content*/}
                                                    <div
                                                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        {/*header*/}
                                                        <div
                                                            className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                            <h3 className="text-3xl font-semibold text-gray-700">
                                                                Site vitrine standard
                                                            </h3>
                                                            <button
                                                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                            </button>
                                                        </div>
                                                        {/*body*/}
                                                        <div className="relative p-6 flex-auto">
                                                            <ul className="my-4 text-slate-500 text-lg leading-relaxed text-gray-700">
                                                                <li>1ère Consultation (1h) : définition du projet (GRATUIT)</li>
                                                                <li>2ème Consultation (1h) : propositions de template OU d'idées design avec ou sans un page builder</li>
                                                                <li>Mise en place du template OU design (page builder)</li>
                                                                <li>Mise en place contenu textes & images</li>
                                                                <li>Site responsive, adaptation tout support</li>
                                                                <li>Interface administrateur (avec pack de plugin standard dans le cas d'un site avec Wordpress)</li>
                                                                <li>Mise en place formulaire de contact</li>
                                                                <li>Accès à un nom de Domaine + Hébergement OU nom de domaine + hébergement+ boîtes mails pro (optionnel)</li>
                                                                <li>Optimisation du référencement (optionnel)</li>
                                                                <li>Association du site aux réseaux sociaux (Dans le cas de leur existence)</li>
                                                                <li>Mise en ligne du site</li>
                                                            </ul>
                                                        </div>
                                                        {/*footer*/}
                                                        <div
                                                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button
                                                                className="text-red-500 bg-pink-50 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Fermer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                    ) : null}
                                </>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundImage: `url(${Blog})`}}
                         className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        {/*Hover*/}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wide'>
                                Blog / Forum
                            </span>
                            <div className='pt-8 text-center'>
                                <p>400€/jour</p>
                                <p>Avec panel admin</p>
                                <p>Possibilité avec Wordpress</p>
                                <>
                                    <button
                                        className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg"
                                        type="button"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Plus
                                    </button>
                                    {showModal ? (
                                        <>
                                            <div
                                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                            >
                                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                    {/*content*/}
                                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        {/*header*/}
                                                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                            <h3 className="text-3xl font-semibold text-gray-700">
                                                                Blog / Forum
                                                            </h3>
                                                            <button
                                                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                            </button>
                                                        </div>
                                                        {/*body*/}
                                                        <div className="relative p-6 flex-auto">
                                                            <ul className="my-4 text-slate-500 text-lg leading-relaxed text-gray-700">
                                                                <li>1ère Consultation (1h) : définition du projet (GRATUIT)</li>
                                                                <li>2ème Consultation (1h) : propositions de template OU d'idées design avec ou sans un page builder</li>
                                                                <li>Mise en place du template OU design (page builder)</li>
                                                                <li>Mise en place contenu textes & images</li>
                                                                <li>Site responsive, adaptation tout support</li>
                                                                <li>Interface administrateur (avec pack de plugin standard dans le cas d'un site avec Wordpress)</li>
                                                                <li>Mise en place formulaire de contact</li>
                                                                <li>Accès à un nom de Domaine + Hébergement OU nom de domaine + hébergement+ boîtes mails pro (optionnel)</li>
                                                                <li>Optimisation du référencement (optionnel)</li>
                                                                <li>Association du site aux réseaux sociaux (Dans le cas de leur existence)</li>
                                                                <li>Mise en ligne du site</li>
                                                            </ul>
                                                        </div>
                                                        {/*footer*/}
                                                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button
                                                                className="text-red-500 bg-pink-50 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Fermer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                    ) : null}
                                </>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundImage: `url(${Ecommerce})`}}
                         className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        {/*Hover*/}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                Boutique en ligne
                            </span>
                            <div className='pt-8 text-center'>
                                <p>A partir de 450€/jour</p>
                                <p>Possibilité avec Wordpress</p>
                                <p>Pack 5 pages</p>
                                <>
                                    <button
                                        className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg"
                                        type="button"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Plus
                                    </button>
                                    {showModal ? (
                                        <>
                                            <div
                                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                            >
                                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                    {/*content*/}
                                                    <div
                                                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        {/*header*/}
                                                        <div
                                                            className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                            <h3 className="text-3xl font-semibold text-gray-700">
                                                                Ecommerce
                                                            </h3>
                                                            <button
                                                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                            </button>
                                                        </div>
                                                        {/*body*/}
                                                        <div className="relative p-6 flex-auto">
                                                            <ul className="my-4 text-slate-500 text-lg leading-relaxed text-gray-700">
                                                                <li>1ère Consultation (1h) : définition du projet (GRATUIT)</li>
                                                                <li>2ème Consultation (1h) : propositions de template OU d'idées design avec ou sans un page builder</li>
                                                                <li>Mise en place du template OU design (page builder)</li>
                                                                <li>Mise en place contenu textes & images</li>
                                                                <li>Site responsive, adaptation tout support</li>
                                                                <li>Interface administrateur (avec pack de plugin standard dans le cas d'un site avec Wordpress)</li>
                                                                <li>Mise en place formulaire de contact</li>
                                                                <li>Accès à un nom de Domaine + Hébergement OU nom de domaine + hébergement+ boîtes mails pro (optionnel)</li>
                                                                <li>Optimisation du référencement (optionnel)</li>
                                                                <li>Mise en place outil de statistiques de visite (optionnel)</li>
                                                                <li>Association du site aux réseaux sociaux (Dans le cas de leur existence)</li>
                                                                <li>Mise en ligne du site</li>
                                                            </ul>
                                                        </div>
                                                        {/*footer*/}
                                                        <div
                                                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button
                                                                className="text-red-500 bg-pink-50 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Fermer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                    ) : null}
                                </>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundImage: `url(${Maintenance})`}}
                         className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        {/*Hover*/}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                Forfait maintenance
                            </span>
                            <div className='pt-8 text-center'>
                                <p>450€/an</p>
                                <p>Mise à jour, débogage</p>
                                <p>Sauvegardes et hébergement web</p>
                                <>
                                    <button
                                        className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg"
                                        type="button"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Plus
                                    </button>
                                    {showModal ? (
                                        <>
                                            <div
                                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                            >
                                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                    {/*content*/}
                                                    <div
                                                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        {/*header*/}
                                                        <div
                                                            className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                            <h3 className="text-3xl font-semibold text-gray-700">
                                                                Création personnalisée
                                                            </h3>
                                                            <button
                                                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                            </button>
                                                        </div>
                                                        {/*body*/}
                                                        <div className="relative p-6 flex-auto">
                                                            <ul className="my-4 text-slate-500 text-lg leading-relaxed text-gray-700">
                                                                <li>Mise à jour internet et plugins</li>
                                                                <li>Petites modifications graphiques ou de contenu</li>
                                                                <li>Sauvegarde mensuelle de la base de données</li>
                                                                <li>Restauration en cas de piratage</li>
                                                            </ul>
                                                        </div>
                                                        {/*footer*/}
                                                        <div
                                                            className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button
                                                                className="text-red-500 bg-pink-50 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                type="button"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Fermer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                        </>
                                    ) : null}
                                </>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundImage: `url(${Personnalise})`}}
                         className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                        {/*Hover*/}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                Création personnalisée
                            </span>
                            <div className='pt-8 text-center'>
                                <p>A partir de 400€/jour</p>
                                <p>Application web ou mobile</p>
                                <Link to="contact" smooth={true} duration={500}>
                                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">Devis</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tarifs;