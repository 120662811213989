import React from "react";

const About = () => {
    return (
        <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-pink-600'>A propos</p>
                    </div>
                    <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 pl-4'>
                    <div className='sm:text-right text-4xl font-bold'>
                        <p>Salut, c'est Elias. Bienvenue sur mon portfolio !</p>
                    </div>
                    <div>
                        <p>Je suis un développeur web et webmobile junior. Je pourrais créer des projets front, back et
                            éventuellement fullstack.
                            Vous aurez un accompagnement adapté à vos besoins.<br/>
                            Je me suis formé à la création de site et
                            d'application web et mobile afin d'accompagner les entreprises et/ou particulier dans leur
                            processus de numérisation.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About