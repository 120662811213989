import React from "react";
import {Link} from "react-scroll";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const Footer = () => {
    const box = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        overflow: 'scroll',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        height: '100%',
        display: 'block'
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <footer className="bg-[#0a192f] text-gray-300">
            <div className="grid grid-cols-2 gap-8 py-8 px-6 md:grid-cols-3 ml-10">
                <div>
                    <h2 className="mb-6 text-sm font-semi-bold text-gray-500 uppercase dark:text-gray-400">Elias
                        Cardon</h2>
                    <ul className="text-gray-500 dark:text-gray-400">
                        <li className="mb-4">
                            <Link to="about" smooth={true} duration={500}>
                                A propos
                            </Link>
                        </li>
                        <li className="mb-4">
                            <a href="https://le-blog-de-jobba.webflow.io/" className="hover:underline">Blog</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Réseaux
                        sociaux</h2>
                    <ul className="text-gray-500 dark:text-gray-400">
                        <li className="mb-4">
                            <a href="https://www.linkedin.com/in/elias-cardon-693a31a2/"
                               className="hover:underline">LinkedIn</a>
                        </li>
                        <li className="mb-4">
                            <a href="https://github.com/elias-cardon" className="hover:underline">Github</a>
                        </li>
                        <li className="mb-4">
                            <a href="https://www.instagram.com/el_eliohs/" className="hover:underline">Instagram</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">Legal</h2>
                    <Button onClick={handleOpen}>Mention légales et CGU</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="scroll" sx={box}>
                            <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                        variant="h4" component="h2">
                                Mentions légales et CGU
                            </Typography>
                            <Typography id="modal-modal-description" sx={{mt: 3}}>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    Définition
                                </Typography>
                                <p className="modal-margin">
                                    <b>Client : </b>tout professionnel ou personne physique capable au sens des articles
                                    1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des
                                    présentes conditions générales.
                                </p>
                                <p className="modal-margin">
                                    <b>Prestations et Services : </b>Ce que ce site met à disposition des Clients.
                                </p>
                                <p className="modal-margin">
                                    <b>Contenu : </b>Ensemble des éléments constituants l’information présente sur le
                                    Site, notamment textes – images – vidéos.
                                </p>
                                <p className="modal-margin">
                                    <b>Informations clients : </b>Ci après dénommé « Information (s) » qui correspondent
                                    à l’ensemble des données personnelles susceptibles d’être détenues par
                                    ce site pour la gestion de la relation client et à des fins
                                    d’analyses et de statistiques.
                                </p>
                                <p className="modal-margin">
                                    <b>Utilisateur : </b> Internaute se connectant, utilisant le site susnommé.
                                </p>
                                <p className="modal-margin">
                                    <b>Informations personnelles : </b>« Les informations qui permettent, sous quelque
                                    forme que ce soit, directement ou non, l'identification des personnes physiques
                                    auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
                                </p>
                                <p className="modal-margin">
                                    Les termes « données à caractère personnel », « personne concernée », « sous
                                    traitant » et « données sensibles » ont le sens défini par le Règlement Général sur
                                    la Protection des Données (RGPD : n° 2016-679).
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    1. Présentation du site
                                </Typography>
                                <p className="modal-margin">
                                    En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                                    l'économie numérique, il est précisé aux utilisateurs du site internet
                                    https://www.elias-cardon.com l'identité des différents intervenants dans le cadre
                                    de sa réalisation et de son suivi:
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h7" component="h4">
                                    Mentions d'identification
                                </Typography>
                                <p className="modal-margin">
                                    <b>Propriétaire</b> : Elias Cardon <br/>
                                    <b>Adresse postale</b> : 30 rue du Rhône, 13008 Marseille<br/>
                                    <b>Contact</b> : 06 52 51 43 86<br/>
                                    <b>Email</b> : elias.cardon.17@gmail.com
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h7" component="h4">
                                    Mentions relatives à l'hébergeur
                                </Typography>
                                <p className="modal-margin">
                                    <b>Nom - Raison sociale</b> : Firebase <br/>
                                    <b>Adresse de l'hébergeur</b> : 30 rue du Rhône, 13008 Marseille<br/>
                                    <b>Numéro de SIRET hébergeur</b> : 06 52 51 43 86<br/>
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    2. Conditions générales d’utilisation du site et des services proposés
                                </Typography>
                                <p className="modal-margin">
                                    Le Site constitue une œuvre de l’esprit protégée par les dispositions du Code de la
                                    Propriété Intellectuelle et des Réglementations Internationales applicables. Le
                                    Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre
                                    compte tout ou partie des éléments ou travaux du Site.
                                </p>
                                <p className="modal-margin">
                                    L’utilisation du site https://www.elias-cardon.com implique l’acceptation pleine
                                    et entière des conditions générales d’utilisation ci-après décrites. Ces conditions
                                    d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les
                                    utilisateurs du site https://www.elias-cardon.com sont donc invités à les
                                    consulter de manière régulière.
                                </p>
                                <p className="modal-margin">
                                    Ce site internet est normalement accessible à tout moment aux utilisateurs. Une
                                    interruption pour raison de maintenance technique peut être toutefois décidée par
                                    https://www.elias-cardon.com, qui s’efforcera alors de communiquer préalablement
                                    aux utilisateurs les dates et heures de l’intervention. Le site web
                                    https://www.elias-cardon.com est mis à jour régulièrement par
                                    https://www.elias-cardon.com responsable. De la même façon, les mentions légales
                                    peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur
                                    qui est invité à s’y référer le plus souvent possible afin d’en prendre
                                    connaissance.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    3. Description des services fournis
                                </Typography>
                                <p className="modal-margin">
                                    Le site internet https://www.elias-cardon.com a pour objet de fournir une
                                    information concernant l’ensemble des activités de la
                                    société : https://www.elias-cardon.com s’efforce de fournir sur le site
                                    https://www.elias-cardon.com des informations aussi précises que possible.
                                    Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des
                                    carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers
                                    partenaires qui lui fournissent ces informations.
                                </p>
                                <p className="modal-margin">
                                    Toutes les informations indiquées sur le site https://www.elias-cardon.com sont
                                    données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les
                                    renseignements figurant sur le site https://www.elias-cardon.com ne sont pas
                                    exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis
                                    leur mise en ligne.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    4. Limitations contractuelles sur les données techniques
                                </Typography>
                                <p className="modal-margin">
                                    Le site utilise la technologie JavaScript, en particulier la bibliothèque ReactJS.
                                    Le
                                    site Internet ne pourra être tenu
                                    responsable de dommages matériels liés à l’utilisation du site. De plus,
                                    l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne
                                    contenant pas de virus et avec un navigateur de dernière génération mis-à-jour Le
                                    site https://www.elias-cardon.com est hébergé chez un prestataire sur le
                                    territoire de l’Union Européenne conformément aux dispositions du Règlement Général
                                    sur la Protection des Données (RGPD : n° 2016-679).
                                </p>
                                <p className="modal-margin">
                                    L’objectif est d’apporter une prestation qui assure le meilleur taux
                                    d’accessibilité. L’hébergeur assure la continuité de son service 24 Heures sur 24,
                                    tous les jours de l’année. Il se réserve néanmoins la possibilité d’interrompre le
                                    service d’hébergement pour les durées les plus courtes possibles notamment à des
                                    fins de maintenance, d’amélioration de ses infrastructures, de défaillance de ses
                                    infrastructures ou si les Prestations et Services génèrent un trafic réputé anormal.
                                </p>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com et l’hébergeur ne pourront être tenus responsables
                                    en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du
                                    matériel informatique et de téléphonie lié notamment à l’encombrement du réseau
                                    empêchant l’accès au serveur.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    5. Propriété intellectuelle et contrefaçons
                                </Typography>
                                <p className="modal-margin">
                                    Les images d'habillage du site et arrière plan de présentation des projets ont été
                                    sélectionnés sur unsplash et freepng, ainsi que de screenshots des projets.
                                    Les icônes ont été implémentées via la librairie react-icons.
                                </p>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com est propriétaire des droits de propriété
                                    intellectuelle et détient les droits d’usage sur tout autre élément non cité
                                    ci-dessus et accessible sur le site internet, notamment les textes, images,
                                    graphismes, logos, vidéos et sons. Toute reproduction, représentation, modification,
                                    publication, adaptation de tout ou une partie des éléments du site, quel que soit le
                                    moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de :
                                    https://www.marine-jacquens.com.
                                </p>
                                <p className="modal-margin">
                                    Toute exploitation non autorisée du site ou de d’un quelconque élément qu’il
                                    contient sera considérée comme constitutive d’une contrefaçon et poursuivie
                                    conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété
                                    Intellectuelle.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    6. Limitations de responsabilité
                                </Typography>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com agit en tant qu’éditeur du site.
                                    https://www.elias-cardon.com est responsable de la qualité et de la véracité du
                                    Contenu qu’il publie.
                                </p>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com ne pourra être tenu responsable des dommages directs
                                    et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet
                                    https://www.elias-cardon.com, et résultant soit de l’utilisation d’un matériel ne
                                    répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug
                                    ou d’une incompatibilité.
                                </p>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com ne pourra également être tenu responsable des
                                    dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance)
                                    consécutifs à l’utilisation du site https://www.elias-cardon.com. Des espaces
                                    interactifs (possibilité de poser des questions dans l’espace contact) sont à la
                                    disposition des utilisateurs. https://www.elias-cardon.com se réserve le droit de
                                    supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui
                                    contreviendrait à la législation applicable en France, en particulier aux
                                    dispositions relatives à la protection des données. Le cas échéant,
                                    https://www.elias-cardon.com se réserve également la possibilité de mettre en
                                    cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de
                                    message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit
                                    le support utilisé (texte, photographie …).
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    7. Gestion des données personnelles
                                </Typography>
                                <p className="modal-margin">
                                    Le Client est informé des réglementations concernant la communication marketing, la
                                    loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique
                                    et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des
                                    Données (RGPD : n° 2016-679).
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    7.1 Responsables de la collecte des données personnelles
                                </Typography>
                                <p className="modal-margin">
                                    En tant que responsable du traitement des données qu’il collecte,
                                    https://www.elias-cardon.com s’engage à respecter le cadre des dispositions
                                    légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de
                                    ses traitements de données, de fournir à ses prospects et clients, à partir de la
                                    collecte de leurs consentements, une information complète sur le traitement de leurs
                                    données personnelles et de maintenir un registre des traitements conforme à la
                                    réalité. Chaque fois que https://www.elias-cardon.com traite des Données
                                    Personnelles, https://www.elias-cardon.com prend toutes les mesures raisonnables
                                    pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au
                                    regard des finalités pour lesquelles https://www.elias-cardon.com les traite.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    7.2 Finalité des données collectées
                                </Typography>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com est susceptible de traiter tout ou une partie des
                                    données :
                                    <ul>
                                        <li className="listing">- pour permettre la navigation sur le site et la gestion
                                            et la traçabilité des
                                            prestations et services commandés par l’utilisateur : données d’utilisation
                                            du site, facturation, historique des commandes, etc.
                                        </li>
                                        <li className="listing">- pour prévenir et lutter contre la fraude informatique
                                            (spamming, hacking…) :
                                            matériel informatique utilisé pour la navigation, l’adresse IP)
                                        </li>
                                        <li className="listing">- pour améliorer la navigation sur le site : données
                                            d’utilisation
                                        </li>
                                        <li className="listing">- pour mener des enquêtes de satisfaction facultatives
                                            sur
                                            https://www.elias-cardon.com, donnée concernée : adresse email
                                        </li>
                                        <li className="listing">- pour mener des campagnes de communication (sms, mail),
                                            données concernées :
                                            numéro de téléphone, adresse email
                                        </li>
                                    </ul>
                                    https://www.elias-cardon.com ne commercialise pas vos données personnelles qui
                                    sont donc uniquement utilisées par nécessité ou à des fins statistiques et
                                    d’analyses.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    7.3 Droit d’accès, de rectification et d’opposition
                                </Typography>
                                <p className="modal-margin">
                                    Conformément à la réglementation européenne en vigueur, les utilisateurs de
                                    https://www.elias-cardon.com disposent des droits suivants :
                                    <ul>
                                        <li className="listing">- droit d'accès (article 15 RGPD) et de rectification
                                            (article 16 RGPD), de mise à jour, de complétude des données des
                                            utilisateurs droit de verrouillage ou d’effacement des données des
                                            utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont
                                            inexactes, incomplètes, équivoques, périmées, ou dont la collecte,
                                            l'utilisation, la communication ou la conservation est interdite
                                        </li>
                                        <li className="listing">- droit de retirer à tout moment un consentement
                                            (article 13-2c RGPD)
                                        </li>
                                        <li className="listing">- droit à la limitation du traitement des données des
                                            utilisateurs (article 18 RGPD)
                                        </li>
                                        <li className="listing">- droit d’opposition au traitement des données des
                                            utilisateurs (article 21 RGPD)
                                        </li>
                                        <li className="listing">- droit à la portabilité des données que les
                                            utilisateurs auront fournies, lorsque ces données font l’objet de
                                            traitements automatisés fondés sur leur consentement ou sur un contrat
                                            (article 20 RGPD)
                                        </li>
                                        <li className="listing">- droit de définir le sort des données des utilisateurs
                                            après leur mort et de choisir à qui https://www.elias-cardon.com devra
                                            communiquer (ou non) ses données à un tiers qu’ils aura préalablement
                                            désigné
                                        </li>
                                    </ul>
                                </p>
                                <p className="modal-margin">
                                    Dès que https://www.elias-cardon.com a connaissance du décès d’un utilisateur et
                                    à défaut d’instructions de sa part, https://www.marine-jacquens.com s’engage à
                                    détruire ses données, sauf si leur conservation s’avère nécessaire à des fins
                                    probatoires ou pour répondre à une obligation légale.
                                </p>
                                <p className="modal-margin">
                                    Si l’utilisateur souhaite savoir comment https://www.elias-cardon.com utilise ses
                                    Données Personnelles, demander à les rectifier ou s’oppose à leur traitement,
                                    l’utilisateur peut contacter https://www.elias-cardon.com par écrit à l’adresse
                                    mail suivante : elias.cardon.17@gmail.com.
                                </p>
                                <p className="modal-margin">
                                    Dans ce cas, l’utilisateur doit indiquer les données personnelles qu’il souhaiterait
                                    que https://www.elias-cardon.com corrige, mette à jour ou supprime, en
                                    s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou
                                    passeport).
                                </p>
                                <p className="modal-margin">
                                    Les demandes de suppression de données personnelles seront soumises aux obligations
                                    qui sont imposées à https://www.elias-cardon.com par la loi, notamment en matière
                                    de conservation ou d’archivage des documents. Enfin, les utilisateurs de
                                    https://www.elias-cardon.com peuvent déposer une réclamation auprès des autorités
                                    de contrôle, et notamment de la <a href="https://www.cnil.fr/fr/plaintes">CNIL</a>.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    7.4 Non-communication des données personnelles
                                </Typography>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com s’interdit de traiter, héberger ou transférer les
                                    informations collectées sur ses clients vers un pays situé en dehors de l’Union
                                    européenne ou reconnu comme « non adéquat » par la Commission européenne sans en
                                    informer préalablement le client. Pour autant, https://www.elias-cardon.com reste
                                    libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il
                                    présentent les garanties suffisantes au regard des exigences du Règlement Général
                                    sur la Protection des Données (RGPD : n° 2016-679).
                                </p>
                                <p className="modal-margin">
                                    https://www.elias-cardon.com s’engage à prendre toutes les précautions
                                    nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne
                                    soient pas communiquées à des personnes non autorisées. Cependant, si un incident
                                    impactant l’intégrité ou la confidentialité des Informations du Client est portée à
                                    la connaissance de https://www.elias-cardon.com, celle-ci devra dans les
                                    meilleurs délais informer le Client et lui communiquer les mesures de corrections
                                    prises. Par ailleurs https://www.elias-cardon.com ne collecte aucune « données
                                    sensibles ».
                                </p>
                                <p className="modal-margin">
                                    Les Données Personnelles de l’utilisateur peuvent être traitées par des filiales de
                                    https://www.elias-cardon.com et des sous-traitants (prestataires de services),
                                    exclusivement afin de réaliser les finalités de la présente politique.
                                </p>
                                <p className="modal-margin">
                                    Dans la limite de leurs attributions respectives et pour les finalités rappelées
                                    ci-dessus, les principales personnes susceptibles d’avoir accès aux données des
                                    utilisateurs de https://www.elias-cardon.com sont principalement les agents de
                                    notre service client.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    8. Notification d’incident
                                </Typography>
                                <p className="modal-margin">
                                    Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et
                                    aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en
                                    conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une
                                    brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils
                                    puissent prendre les mesures appropriées. Nos procédures de notification d’incident
                                    tiennent compte de nos obligations légales, qu'elles se situent au niveau national
                                    ou européen. Nous nous engageons à informer pleinement nos clients de toutes les
                                    questions relevant de la sécurité de leur compte et à leur fournir toutes les
                                    informations nécessaires pour les aider à respecter leurs propres obligations
                                    réglementaires en matière de reporting.
                                </p>
                                <p className="modal-margin">
                                    Aucune information personnelle de l'utilisateur du site
                                    https://www.elias-cardon.com n'est publiée à l'insu de l'utilisateur, échangée,
                                    transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse
                                    du rachat de https://www.elias-cardon.com et de ses droits permettrait la
                                    transmission des dites informations à l'éventuel acquéreur qui serait à son tour
                                    tenu de la même obligation de conservation et de modification des données vis à vis
                                    de l'utilisateur du site https://www.elias-cardon.com.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    Sécurité
                                </Typography>
                                <p className="modal-margin">
                                    Pour assurer la sécurité et la confidentialité des données personnelles,
                                    https://www.elias-cardon.com utilise des réseaux protégés par des dispositifs
                                    standards tels que par pare-feu.
                                </p>
                                <p className="modal-margin">
                                    Lors du traitement des Données Personnelles, https://www.elias-cardon.com prend
                                    toutes les mesures raisonnables visant à les protéger contre toute perte,
                                    utilisation détournée, accès non autorisé, divulgation, altération ou destruction.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    9. Liens hypertextes
                                </Typography>
                                <p className="modal-margin">
                                    Le site https://www.elias-cardon.com contient un certain nombre de liens
                                    hypertextes vers d’autres sites, mis en place avec l’autorisation de
                                    https://www.elias-cardon.com. Cependant, https://www.elias-cardon.com n’a pas
                                    la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en
                                    conséquence aucune responsabilité de ce fait.
                                </p>
                                <Typography className="inline border-b-4 border-pink-600" id="modal-modal-title"
                                            variant="h6" component="h3">
                                    10. Droit applicable et attribution de juridiction
                                </Typography>
                                <p className="modal-margin">
                                    Tout litige en relation avec l’utilisation du site https://www.elias-cardon.com
                                    est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est
                                    fait attribution exclusive de juridiction aux tribunaux compétents de Marseille.
                                </p>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
            <div className="py-6 px-4 bg-[#0a192f] text-gray-300 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© 2022 <a
            href="https://flowbite.com/">Elias Cardon</a>. Tous droits réservés.
        </span>
            </div>
        </footer>

    )
}

export default Footer