import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Tarifs from "./components/Tarifs";
import Footer from "./components/Footer";


function App() {
    return (
        <div>
            <Navbar/>
            <Home/>
            <About/>
            <Services/>
            <Skills/>
            <Work/>
            <Tarifs/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
