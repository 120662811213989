import React from "react";

const Services = () => {
    return(
        <div name='services' className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Mes services</p>
                    <p className='py-4'>// Un accompagnement adapté à votre projet.</p>
                </div>

                <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-4 text-center py-8'>
                    <div className='shadow-md shadow-[#040c16] p-5'>
                        <p className='my-4 text-xl font-bold'>CONCEPTION SYSTÈME & MAQUETTAGE</p>
                        <p>Diagramme de conception (UML), rédaction cahier des charges, zoning, wireframe, prototype.</p>
                        <p className='my-4 mt-20 text-xl font-bold'>GESTION DE PROJET WEB</p>
                        <p>Création et mise en ligne de site vitrine, e-commerce, solution personnalisée, application web et mobile.</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] p-5'>
                        <p className='my-4 text-xl font-bold'>REFERENCEMENT SEO</p>
                        <p>Optimisation des ancres et mots-clés</p>
                        <p className='my-4 mt-20 text-xl font-bold'>REFONTE SITE & APPLICATION</p>
                        <p>Mise à jour du site / application.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services